<template>
  <app-layout
    title="Dashboard"
    :description="`Welcome ${
      business?.name || business?.business.name || ''
    }, what do you want to do today?`"
  >
    <section v-if="business" class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8">
      <kyc-banner
        v-if="business && business.business.kyc_status !== 'APPROVED'"
      />
      <div class="w-full mt-5">
        <dashboard-balances />
        <div
          class="flex flex-wrap xl:flex-nowrap items-start justify-between gap-y-10 gap-x-10 mt-10"
        >
          <div
            class="w-full rounded-[13px] border border-greyscale-1 overflow-hidden"
            :class="canAccessCenturion ? 'xl:w-[65%]' : 'xl:w-[100%]'"
          >
            <header class="flex justify-between items-center w-full px-5 py-4">
              <h2 class="text-primary text-base font-[800]">
                Recent transactions
              </h2>
              <router-link
                class="flex gap-x-[2px] font-medium text-primary text-sm"
                to="/transactions"
              >
                <span>See all</span>
                <arrow-right-icon />
              </router-link>
            </header>
            <TabGroup v-if="showTabs" as="section">
              <TabList class="mt-0 px-0 flex w-full">
                <Tab v-slot="{ selected }" as="template">
                  <button
                    :class="`w-auto flex justify-center items-center py-2 h-8 px-8 focus:outline-none text-xs ${
                      selected
                        ? 'text-primary font-[800] border-b-2 border-primary/60 '
                        : 'text-text-primary  font-medium border-b border-greyscale-1'
                    } `"
                  >
                    Fiat
                  </button>
                </Tab>
                <Tab v-slot="{ selected }" as="template">
                  <button
                    :class="`w-auto flex justify-center items-center py-2 h-8 px-8 focus:outline-none text-xs ${
                      selected
                        ? 'text-primary font-[800] border-b-2 border-primary/60'
                        : 'text-text-primary font-medium border-b border-greyscale-1'
                    } `"
                  >
                    Stablecoins
                  </button>
                </Tab>
              </TabList>
              <TabPanels class="min-h-[350px]">
                <TabPanel class="w-full px-0">
                  <dashboard-banking-transactions />
                </TabPanel>
                <TabPanel class="w-full px-0">
                  <dashboard-crypto-transactions />
                </TabPanel>
              </TabPanels>
            </TabGroup>
            <div v-else-if="canAccessCenturion" class="w-full">
              <dashboard-crypto-transactions />
            </div>
            <div v-else-if="canAccessHelios" class="w-full">
              <dashboard-banking-transactions />
            </div>
            <empty-state v-else feature="transactions" />
          </div>
          <swap-assets v-if="canAccessCenturion" />
        </div>
      </div>
    </section>
  </app-layout>
</template>

<script setup lang="ts">
import { useLoggedInBusiness } from "@/composables/states";
import { useFeature } from "@/composables/use-feature";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { computed } from "vue";

const { business } = useLoggedInBusiness();
const { canAccessCenturion, canAccessHelios } = useFeature();

const showTabs = computed(
  () => canAccessCenturion.value && canAccessHelios.value,
);
</script>
